import React, { useEffect } from 'react';
import { Container, Row, Col, Image, Button, Nav } from 'react-bootstrap';
import { Link } from 'gatsby';
import HeaderExpanded from '../components/HeaderExpanded/HeaderExpanded';
import '../styles/disease-models.scss';
import { Helmet } from 'react-helmet';

function DiseaseModels() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const title = 'Pest & Disease Solutions';
  const image = process.env.GATSBY_S3_BUCKET_ASSETS + 'images/solutions.jpg';

  return (
    <>
      {/* Add meta tags inside here. */}
      <Helmet>
        <title>HortPlus | Digital Pest &amp; Disease Tools</title>
        <meta
          name='description'
          content='Digital Platform Solutions for Pest &amp; Disease Management'
        />
      </Helmet>
      <HeaderExpanded title={title} image={image} />
      <Container className='mt-5'>
        <Row className='pb-5'>
          <Col>
            <Container as='h2' className='hp-title pb-5 text-center mt-5'>
              Digital Platform Solutions for Pest &amp; Disease Management
            </Container>

            <p>
              The MetWatch Platform provides a central source of live weather
              and disease risk. The platform integrates into the daily process
              of growers and agronomists to inform decisions and identify
              high-risk periods to assist with targeting spray applications when
              they are needed while reducing overall spray use.
            </p>

            <p>
              The platform is a central point of access for decision support
              tools and features a full suite of disease risk models and
              advanced tools to inform management decisions. We are able to
              customise our disease models and integrations with business
              operating procedures and additional inputs. We are able to draw up
              on our expertise in bringing disease risk models to market to
              build new or advanced models for specific use cases including
              market compliance and biosecurity.
            </p>
          </Col>
        </Row>
      </Container>

      <Container>
        <Row className='pb-5'>
          <Col md={6} className='pr-md-5 mb-3 mb-md-0'>
            <h2 className='pb-3'>
              Partnering with Industry to Deliver Research &amp; Extension
            </h2>
            <p>
              We help industry by reducing the complexity of developing the
              infrastructure for digital tools by providing a proven and
              extendable platform that integrates with a range of existing
              systems for ease of access.
            </p>
            <p>
              Our platform is a powerful pathway for delivery of research and
              extension that is accessible to growers in one place.
            </p>
          </Col>
          <Col
            xs={12}
            md={6}
            className='d-flex justify-content-center align-items-center'
          >
            <Nav className='justify-content-center align-items-center'>
              <Nav.Item className='hp-brands-nav__item'>
                <Nav.Link href='https://www.applesandpears.nz/' target='_blank'>
                  <img
                    src={
                      process.env.GATSBY_S3_BUCKET_ASSETS +
                      'brands/nz_apples_and_pears.png'
                    }
                    alt='NZ Apples &amp; Pears Logo'
                    width='120'
                  />
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className='hp-brands-nav__item'>
                <Nav.Link
                  href='https://www.summerfruitnz.co.nz/'
                  target='_blank'
                >
                  <img
                    src={
                      process.env.GATSBY_S3_BUCKET_ASSETS +
                      'brands/summerfruit_nz.png'
                    }
                    alt='Summerfruit NZ Logo'
                    width='122'
                  />
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className='hp-brands-nav__item'>
                <Nav.Link href='https://www.onionsnz.com/' target='_blank'>
                  <img
                    src={
                      process.env.GATSBY_S3_BUCKET_ASSETS +
                      'brands/onions_nz.png'
                    }
                    alt='NZ Onions Logo'
                    width='128'
                  />
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className='hp-brands-nav__item'>
                <Nav.Link href='https://www.far.org.nz/' target='_blank'>
                  <img
                    src={process.env.GATSBY_S3_BUCKET_ASSETS + 'brands/far.png'}
                    alt='HortPlus Logo'
                    width='96'
                  />
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className='hp-brands-nav__item'>
                <Nav.Link href='https://www.zespri.com/' target='_blank'>
                  <img
                    src={
                      process.env.GATSBY_S3_BUCKET_ASSETS + 'brands/zespri.png'
                    }
                    alt='Zespri Logo'
                    width='101'
                  />
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className='hp-brands-nav__item'>
                <Nav.Link href='https://kvh.org.nz/' target='_blank'>
                  <img
                    src={process.env.GATSBY_S3_BUCKET_ASSETS + 'brands/kvh.png'}
                    alt='Kiwifruit Vine Health Logo'
                    width='90'
                  />
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
        </Row>
      </Container>

      <Container className='my-5 pb-2'>
        <hr></hr>
        <Row className='my-3 pt-5'>
          <Col>
            <Container as='h2' className='hp-title pb-5 text-center'>
              Our Design Process
            </Container>

            <p className='text-center'>
              Developing a disease model for industry involves undertaking a
              design process to engage stakeholders and understand the outcomes
              that are to be achieved. We are able to draw upon our experience
              and relationships with growers and key experts to bring new
              disease models to market and to refine and expand existing models.
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <Image
              src={
                process.env.GATSBY_S3_BUCKET_ASSETS +
                'images/disease_model_process.svg'
              }
              alt='Disease Model Process'
              className='d-block mx-auto'
              width='700'
              fluid
            />
          </Col>
        </Row>
      </Container>

      <Container fluid className='bg-light hp-padding-100'>
        <Container>
          <h1 className='pb-3'>
            Case Study: PSA Risk Model for Kiwifruit Industry
          </h1>
          <p className='hp-font-22'>
            The Kiwifruit industry approached HortPlus. PSA had presented a
            major challenge to the industry and they wanted to implement an
            interactive PSA Risk Model for growers. We took them through our
            design process to develop and deliver a digital solution.
          </p>

          <Row>
            <Col>
              <p>
                The immediate need was to create a PSA risk model for growers.
                We also identified a need for other tools, including a Chill
                Unit Calculator and weather forecasting tools to further enable
                decision making.
              </p>
              <p>
                We worked with Plant &amp; Food Research and Kiwifruit Vine
                Health (KVH) to ideate the implementation of the project and
                build a timeline and roadmap for delivery of the solution,
                including the installation of new weather stations.
              </p>
              <p>
                We developed and successfully launched the project, integrating
                it with existing Industry web tools. We have continued to work
                with both Plant &amp; Food Research (science) and KVH (industry)
                to refine and evolve the tools and models.
              </p>
            </Col>
          </Row>
          <Row className='p-4'>
            <Col
              xs={12}
              md={5}
              className='d-flex justify-content-center align-items-center'
            >
              {' '}
              <Image
                src={
                  process.env.GATSBY_S3_BUCKET_ASSETS + 'images/iphone_psa.png'
                }
                alt='Disease Model Sample'
                className='d-block mx-auto p-4'
                width='200'
                fluid
              />
            </Col>
            <Col
              xs={12}
              md={7}
              className='d-flex justify-content-center align-items-center'
            >
              <Image
                src={
                  process.env.GATSBY_S3_BUCKET_ASSETS + 'images/desktop_psa.png'
                }
                alt='Disease Model Sample'
                className='d-block mx-auto'
                width='700'
                fluid
              />
            </Col>
          </Row>
        </Container>
      </Container>

      <Container className='mb-5'>
        <Row className='pt-5'>
          <Col md={'6'} className='mb-4 mb-md-0'>
            <p className='hp-font-22'>Disease Risk Modelling</p>
            <p>
              The MetWatch Platform allows growers to predict and manage
              potential disease outbreaks, enhance day-to-day crop management,
              and make better decisions to improve crops and overall profit.
            </p>
            <p>
              Inadequate disease control can result in poor crop health, high
              crop losses, decreased productivity and quality, and long-term
              damage to ecosystems. With timely and accurate data, both historic
              and forecasted, growers can anticipate disease occurrence and be
              proactive by spraying crops with accuracy and efficiency.
            </p>
            <p>
              HortPlus has worked with some of New Zealand's top scientists to
              develop and refine disease risk models &amp; pest phenology
              models. Our tools, weather data, weather forecast and disease risk
              modelling combine to support decision making for crop diseases in
              pipfruit, stonefruit, kiwifruit, grapes, and field crops.
            </p>
          </Col>
          <Col xs={12} md={{ span: 5, offset: 1 }}>
            <h6 className='hp-title--subtitle-half pb-3'>
              Disease models we have implemented for industry include:
            </h6>
            <h5>Grapes</h5>
            <ul>
              <li>Botrytis (Bacchus)</li>
              <li>Botrytis (Broome)</li>
              <li>Downy Mildew</li>
              <li>Powdery Mildew</li>
            </ul>
            <h5>Field Crops</h5>
            <ul>
              <li>Onion Downy Mildew</li>
              <li>Onion White Rot</li>
              <li>Onion Stemphylium Leaf Blight</li>
            </ul>
            <h5>Apples</h5>
            <ul>
              <li>Asco Spore</li>
              <li>Blackspot (Apple scab)</li>
              <li>Integrated Blackspot Model</li>
              <li>Fire Blight</li>
              <li>Codling Moth</li>
              <li>Elsinoe</li>
            </ul>
            <h5>Kiwifruit</h5>
            <ul>
              <li>PSA-V</li>
            </ul>
            <h5>Summerfruit</h5>
            <ul>
              <li>Brown Rot</li>
              <li>Leaf Curl</li>
              <li>Leaf Rust</li>
            </ul>
          </Col>
        </Row>
        <hr></hr>

        <Row>
          <Col className='text-center hp-padding-100 '>
            <h1 className='hp-title hp-title--subtitle'>
              For New Research and Disease Model Projects
            </h1>
            <p className='d-block text-center'>
              <Button as={Link} to={'/contact'} className='my-5'>
                Get in touch
              </Button>
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default DiseaseModels;
